@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500&family=Montserrat&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  width: 100vw;
  background: url("https://images.unsplash.com/photo-1559157244-37aba67eefce?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-decoration: none;
  /* position: static !important; */
}

input::placeholder { /* WebKit browsers */
  color:    #fff !important;
}
textarea::placeholder { /* WebKit browsers */
  color:    #fff !important;
}

input:read-only {
  pointer-events: none;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  background: rgb(39, 72, 96);
}

::-webkit-scrollbar {
  appearance: none;
  height: 14px;
  width: 14px;
  background: rgba(39, 72, 96, 0.6);
}


::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(39, 72, 96);
}